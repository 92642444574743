.ant-btn .anticon {
    vertical-align: 0.06em;
}
.ant-row {
    margin: 0 !important;
}
.overlay {
    position: relative !important;
}
.ant-tabs >.ant-tabs-nav .ant-tabs-nav-more {
    color: #000;
    padding: 0px 16px;
}
.ant-pro-card .ant-pro-card-body{
    padding-inline: 4px;
    padding-block: 4px;
}
.ant-pro-table-alert-container {
    padding-block: 8px;
    padding-inline: 16px;
}
.ant-pro-table-list-toolbar-container {
    padding-block: 0 8px;
}
.ant-tabs.ant-tabs-table-header .ant-tabs-nav {
    margin-bottom: 0px !important;
}
.ant-tabs.ant-tabs-table-header .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 16px;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-row .ant-table-cell {
    padding: 4px 4px;
}

.ant-advanced-search-form .ant-form-item{
    margin-bottom: 0px;
}