.lg-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0;
    -webkit-transition: opacity .15s ease 0s;
    -o-transition: opacity .15s ease 0s;
    transition: opacity .15s ease 0s;

}

.lg-backdrop.in {
    opacity: 80% !important;
}

.lg-outer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    opacity: 0;
    -webkit-transition: opacity .15s ease 0s;
    -o-transition: opacity .15s ease 0s;
    transition: opacity .15s ease 0s;
}


.lg-outer.lg-visible {
    opacity: 1;
}

.lg-outer {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    ::after,
    ::before {
        box-sizing: inherit;
    }
}

.lg-outer,
.lg-outer .lg,
.lg-outer .lg-inner {
    width: 100%;
    height: 100%;
}

.lg-outer .lg-inner {
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
}

.lg-outer .lg-item {
    // background: url(../img/loading.gif) center center no-repeat;
    display: none !important;
}

.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.lg-outer.lg-has-thumb .lg-item {
    padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
    padding-bottom: 0;
}

.lg-outer.lg-css .lg-current,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide,
.lg-outer.lg-css3 .lg-prev-slide {
    display: inline-block !important;
}

.lg-outer .lg-item.lg-complete {
    background-image: none;
}

.lg-css3.lg-use-css3 .lg-item,
.lg-css3.lg-use-left .lg-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.lg-outer.lg-css3 .lg-item.lg-current,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-prev-slide {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
    opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
}

.lg-outer .lg-img-wrap:before,
.lg-outer .lg-item:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transition: -webkit-transform .3s cubic-bezier(0, 0, .25, 1) 0s;
    -moz-transition: -moz-transform .3s cubic-bezier(0, 0, .25, 1) 0s;
    -o-transition: -o-transform .3s cubic-bezier(0, 0, .25, 1) 0s;
    transition: transform .3s cubic-bezier(0, 0, .25, 1) 0s;
}

.lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important;
}

.lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity .15s ease 0s;
    -o-transition: opacity .15s ease 0s;
    transition: opacity .15s ease 0s;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
    -webkit-transform: scale3d(.5, .5, .5);
    transform: scale3d(.5, .5, .5);
    opacity: 0;
    -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    -o-transition: -o-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    transition: transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: -webkit-transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
    -moz-transition: -moz-transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
    -o-transition: -o-transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
    transition: transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
}

.lg-outer .lg-item.lg-current {
    z-index: 1060;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.lg-toolbar {
    z-index: 1082;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .45);
}

.lg-next,
.lg-prev,
.lg-toolbar {
    opacity: 1;
    -webkit-transition: -webkit-transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear;
    -moz-transition: -moz-transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear;
    -o-transition: -o-transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear;
    transition: transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear;
}

.lg-hide-items .lg-toolbar {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
}

.group:after,
.group:before {
    display: table;
    content: "";
    line-height: 0;
}

.lg-icon {
    font-family: lg;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: 0;
    background: 0 0;
    border: none;
    box-shadow: none;
    -webkit-transition: color .2s linear;
    -o-transition: color .2s linear;
    transition: color .2s linear;
}

.lg-toolbar .lg-close:after {
    content: "\e070";
}

.lg-toolbar .lg-download::after {
    content: "\e0f2";
}

#lg-zoom-in:after {
    content: "\e311";
}

#lg-zoom-out:after {
    content: "\e312";
}

#lg-actual-size:after {
    content: "\e033";
}

#lg-counter {
    color: #999;
    display: inline-block;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 12px;
    vertical-align: middle;
}

.group:after {
    clear: both;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
    border-radius: 2px;
    color: #999;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-top: -10px;
    padding: 8px 10px 9px;
    position: absolute;
    top: 50%;
    z-index: 1080;
    outline: 0;
    border: none;
    background-color: transparent;
}

.lg-actions .lg-prev {
    left: 20px;
}

.lg-hide-items .lg-prev {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
}

.lg-actions .lg-prev:after {
    content: "\e094";
}

.lg-actions .lg-next {
    right: 20px;
}

.lg-hide-items .lg-next {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
}

.lg-actions .lg-next:before {
    content: "\e095";
}

.lg-sub-html {
    background-color: rgba(0, 0, 0, .45);
    bottom: 0;
    color: #EEE;
    font-size: 16px;
    left: 0;
    padding: 10px 40px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 1080;
}

.lg-outer .lg-empty-html,
.lg-outer.lg-hide-download #lg-download {
    display: none;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
    -webkit-transition: bottom .25s ease;
    -o-transition: bottom .25s ease;
    transition: bottom .25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
    bottom: 100px;
}

.lg-outer .lg-thumb-outer {
    background-color: #0D0A0A;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1080;
    max-height: 350px;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1) 0s;
    -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1) 0s;
    -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s;
    transition: transform .25s cubic-bezier(0, 0, .25, 1) 0s;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.lg-outer .lg-thumb {
    padding: 10px 0;
    height: 100%;
    margin-bottom: -5px;
}

.lg-outer .lg-thumb-item {
    cursor: pointer;
    float: left;
    overflow: hidden;
    height: 100%;
    border: 2px solid #FFF;
    border-radius: 4px;
    margin-bottom: 5px;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}

.lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
    border-color: #a90707;
}

.lg-outer .lg-toggle-thumb {
    background-color: #0D0A0A;
    border-radius: 2px 2px 0 0;
    color: #999;
    cursor: pointer;
    font-size: 24px;
    height: 39px;
    line-height: 27px;
    padding: 5px 0;
    position: absolute;
    right: 20px;
    text-align: center;
    top: -39px;
    width: 50px;
    outline: 0;
    border: none;
}

.lg-outer .lg-toggle-thumb:after {
    content: "\e1ff";
}