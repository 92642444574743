// VIP ORGANIZATION

// --- NGHIEPVU ---
.text-nghiepvu {
  color: #2B296D !important;
}

.text-hover-nghiepvu {
  transition: $transition-link;

  &:hover {
    transition: $transition-link;
    color: #150175 !important;

    i {
      color: #2B296D !important;
    }

    .svg-icon {
      @include svg-icon-color(#2B296D);
    }
  }
}

.btn-nghiepvu {
  color: #ffffff !important;
  background-color: #2B296D !important;
  border-color: #2B296D !important;
}

.nghiepvu a {
  color: #2B296D;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #150175;
  }
}

.nghiepvu .carousel-indicators {
  margin-bottom: -50px;
}

.nghiepvu .carousel-indicators li {
  background-color: #150175;
  width: 60px;
  height: 8px;
  border-radius: 0.42rem !important;
}

.nghiepvu .carousel-indicators .active {
  background-color: #2B296D;
}

.nghiepvu .aside {
  background-color: #150175;
}

.nghiepvu .aside-menu {
  background-color: #150175;
}

.nghiepvu .brand {
  background-color: #09023a !important;
}

.nghiepvu.aside-menu .menu-link:hover,
.nghiepvu.aside-menu .menu-link:active,
.nghiepvu.aside-menu .menu-item.menu-item-active .menu-link,
.nghiepvu.aside-menu .menu-item.menu-item-open .menu-link {
  background-color: #FDB913 !important;
}

.nghiepvu.aside-menu .menu-item.menu-item-open .menu-item-children .menu-link {
  background-color: #42286f !important;
}

.nghiepvu.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading,
.nghiepvu.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link,
.nghiepvu.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link:hover {
  background-color: #FDB913 !important;
}

.nghiepvu .bg-card-report {
  background-color: #00a79e !important
}

.nghiepvu .bg-primary {
  background-color: #150175 !important;
}

.nghiepvu .border-primary {
  border-color: #2B296D !important;
}

.nghiepvu .nav-text {
  color: #150175 !important;
}

.nghiepvu .navi .navi-item .navi-link:hover {
  color: #150175 !important;
}

.nghiepvu .navi .navi-item .navi-link:hover .navi-icon i {
  color: #150175 !important;
}

.nghiepvu .navi .navi-item .navi-link:hover .navi-text {
  color: #150175 !important;
}

.nghiepvu .dropzone-primary {
  border-color: #2B296D !important;
}

.nghiepvu .text-primary {
  color: #2B296D !important;
}

.nghiepvu a.text-hover-primary:hover,
.text-hover-primary:hover {
  color: #150175 !important;
}

.nghiepvu .accordion {
  .card {
    overflow: visible !important;

    &:last-child {
      margin-bottom: 0;
    }

    .card-header {
      cursor: pointer;
      margin: 0;
      border-bottom: 0;
      padding: 0;
      background-color: $gray-100;

      .card-title {
        padding: get($accordion-config, card-spacer-y) get($accordion-config, card-spacer-x);
        margin: 0;
        font-size: get($accordion-config, card-title-font-size);
        font-weight: 500;
        color: #2B296D;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: $transition;

        >i {
          margin-right: 1rem;
          font-size: get($accordion-config, card-icon-font-size);
          color: #2B296D;
          transition: $transition;
        }

        .card-label {
          flex-grow: 1;
        }

        &.collapsed {
          color: $dark-75;
          transition: $transition;

          >i {
            color: $text-muted;
            transition: $transition;
          }
        }

        &:after {
          right: 1rem;
        }
      }

      .card-text {
        flex-grow: 1;
      }
    }

    .card-body {
      padding: get($accordion-config, card-spacer-y) get($accordion-config, card-spacer-x);
      font-size: 1rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-outline {
    .card {
      background-color: transparent;
    }
  }
}

.nghiepvu .btn-primary {
  background-color: #00a79e !important;
  border-color: #00a79e !important;
}

.nghiepvu .btn-primary:hover {
  background-color: #FDB913 !important;
  border-color: #FDB913 !important;
}

.nghiepvu .btn-primary.active {
  background-color: #FDB913 !important;
  border-color: #FDB913 !important;
}

.nghiepvu .btn-login {
  background-color: #2B296D !important;
  border-color: #2B296D !important;
}

.nghiepvu .btn-login:hover {
  background-color: #FDB913 !important;
  border-color: #FDB913 !important;
}

.nghiepvu .splash-spinner .path {
  stroke: #00a79e !important;
}

.nghiepvu .spinner.spinner-primary:before {
  border: 2px solid #150175;
}

.nghiepvu .modal .modal-header {
  background-color: #2B296D !important;
}

.nghiepvu .nav-icon i {
  color: #2B296D !important;
}

.nghiepvu .text-menu {
  color: #FFD400;
}

.nghiepvu .header-menu .menu-item.menu-item-active>.menu-link .menu-text,
.nghiepvu .header-menu .menu-item>.menu-link .menu-text:hover,
.nghiepvu .header-menu .menu-item>.menu-link .menu-text:active {
  color: #2B296D !important;
}

.nghiepvu.aside-menu .menu-item:hover>.menu-link .menu-text {
  color: #ffffff !important;
}

.nghiepvu .nav.nav-tabs.nav-tabs-line .nav-link.active {
  border-bottom: 1px solid #2B296D;
}

.nghiepvu .nav .show>.nav-link,
.nav .nav-link:hover,
.nav .nav-link.active {
  color: #150175 !important;
}

.nghiepvu .svg-icon.svg-icon-primary svg g [fill] {
  fill: #150175 !important;
}

.nghiepvu .brand-dark .brand .btn.active .svg-icon svg g [fill],
.brand-dark .brand .btn:hover .svg-icon svg g [fill] {
  fill: #ffffff !important;
}

.nghiepvu .btn.btn-light-primary {
  color: #2B296D !important;
}

.nghiepvu .btn.btn-light-primary i {
  color: #2B296D !important;
}

.nghiepvu .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-primary:focus:not(.btn-text),
.btn.btn-light-primary.focus:not(.btn-text) {
  background-color: #2B296D !important;
  color: #ffffff !important;
}

.nghiepvu .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.btn.btn-light-primary:focus:not(.btn-text) i,
.btn.btn-light-primary.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.nghiepvu .checkbox>input:checked~span {
  background-color: #00a79e !important;
}

.nghiepvu .btn-card-hover-primary {
  $btn-background: null;
  $btn-border: null;
  $btn-color: null;
  $btn-icon: null;
  $btn-box-shadow: null;

  &:hover {
    color: #ffffff !important;
    background-color: #2B296D !important;
    border-color: #2B296D !important;
  }

  &:active {
    color: #ffffff !important;
    background-color: #150175 !important;
    border-color: #150175 !important;
  }
}

.nghiepvu .btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  color: #ffffff !important;
  background-color: #FDB913 !important;
  border-color: #FDB913 !important;
}

.nghiepvu .nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line .nav-link.active,
.nav.nav-tabs.nav-tabs-line .show>.nav-link {
  border-bottom: 1px solid #150175 !important;
}

.nghiepvu .pulse.pulse-primary .pulse-ring {
  border-color: #150175 !important;
}

// --- NGHIEPVU --- 

// --- TINGTONG ---
.text-tingtong {
  color: #2B296D !important;
}

.text-hover-tingtong {
  transition: $transition-link;

  &:hover {
    transition: $transition-link;
    color: #150175 !important;

    i {
      color: #2B296D !important;
    }

    .svg-icon {
      @include svg-icon-color(#2B296D);
    }
  }
}

.btn-tingtong {
  color: #ffffff !important;
  background-color: #2B296D !important;
  border-color: #2B296D !important;
}

.tingtong a {
  color: #2B296D;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #150175;
  }
}

.tingtong .carousel-indicators {
  margin-bottom: -50px;
}

.tingtong .carousel-indicators li {
  background-color: #150175;
  width: 60px;
  height: 8px;
  border-radius: 0.42rem !important;
}

.tingtong .carousel-indicators .active {
  background-color: #2B296D;
}

.tingtong .aside {
  background-color: #150175;
}

.tingtong .aside-menu {
  background-color: #150175;
}

.tingtong .brand {
  background-color: #09023a !important;
}

.tingtong.aside-menu .menu-link:hover,
.tingtong.aside-menu .menu-link:active,
.tingtong.aside-menu .menu-item.menu-item-active .menu-link,
.tingtong.aside-menu .menu-item.menu-item-open .menu-link {
  background-color: #FDB913 !important;
}

.tingtong.aside-menu .menu-item.menu-item-open .menu-item-children .menu-link {
  background-color: #42286f !important;
}

.tingtong.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading,
.tingtong.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link,
.tingtong.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link:hover {
  background-color: #FDB913 !important;
}

.tingtong .bg-card-report {
  background-color: #00a79e !important
}

.tingtong .bg-primary {
  background-color: #150175 !important;
}

.tingtong .border-primary {
  border-color: #2B296D !important;
}

.tingtong .nav-text {
  color: #150175 !important;
}

.tingtong .navi .navi-item .navi-link:hover {
  color: #150175 !important;
}

.tingtong .navi .navi-item .navi-link:hover .navi-icon i {
  color: #150175 !important;
}

.tingtong .navi .navi-item .navi-link:hover .navi-text {
  color: #150175 !important;
}

.tingtong .dropzone-primary {
  border-color: #2B296D !important;
}

.tingtong .text-primary {
  color: #2B296D !important;
}

.tingtong a.text-hover-primary:hover,
.text-hover-primary:hover {
  color: #150175 !important;
}

.tingtong .accordion {
  .card {
    overflow: visible !important;

    &:last-child {
      margin-bottom: 0;
    }

    .card-header {
      cursor: pointer;
      margin: 0;
      border-bottom: 0;
      padding: 0;
      background-color: $gray-100;

      .card-title {
        padding: get($accordion-config, card-spacer-y) get($accordion-config, card-spacer-x);
        margin: 0;
        font-size: get($accordion-config, card-title-font-size);
        font-weight: 500;
        color: #2B296D;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: $transition;

        >i {
          margin-right: 1rem;
          font-size: get($accordion-config, card-icon-font-size);
          color: #2B296D;
          transition: $transition;
        }

        .card-label {
          flex-grow: 1;
        }

        &.collapsed {
          color: $dark-75;
          transition: $transition;

          >i {
            color: $text-muted;
            transition: $transition;
          }
        }

        &:after {
          right: 1rem;
        }
      }

      .card-text {
        flex-grow: 1;
      }
    }

    .card-body {
      padding: get($accordion-config, card-spacer-y) get($accordion-config, card-spacer-x);
      font-size: 1rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-outline {
    .card {
      background-color: transparent;
    }
  }
}

.tingtong .btn-primary {
  background-color: #00a79e !important;
  border-color: #00a79e !important;
}

.tingtong .btn-primary:hover {
  background-color: #FDB913 !important;
  border-color: #FDB913 !important;
}

.tingtong .btn-primary.active {
  background-color: #FDB913 !important;
  border-color: #FDB913 !important;
}

.tingtong .btn-login {
  background-color: #2B296D !important;
  border-color: #2B296D !important;
}

.tingtong .btn-login:hover {
  background-color: #FDB913 !important;
  border-color: #FDB913 !important;
}

.tingtong .splash-spinner .path {
  stroke: #00a79e !important;
}

.tingtong .spinner.spinner-primary:before {
  border: 2px solid #150175;
}

.tingtong .modal .modal-header {
  background-color: #2B296D !important;
}

.tingtong .nav-icon i {
  color: #2B296D !important;
}

.tingtong .text-menu {
  color: #FFD400;
}

.tingtong .header-menu .menu-item.menu-item-active>.menu-link .menu-text,
.tingtong .header-menu .menu-item>.menu-link .menu-text:hover,
.tingtong .header-menu .menu-item>.menu-link .menu-text:active {
  color: #2B296D !important;
}

.tingtong.aside-menu .menu-item:hover>.menu-link .menu-text {
  color: #ffffff !important;
}

.tingtong .nav.nav-tabs.nav-tabs-line .nav-link.active {
  border-bottom: 1px solid #2B296D;
}

.tingtong .nav .show>.nav-link,
.nav .nav-link:hover,
.nav .nav-link.active {
  color: #150175 !important;
}

.tingtong .svg-icon.svg-icon-primary svg g [fill] {
  fill: #150175 !important;
}

.tingtong .brand-dark .brand .btn.active .svg-icon svg g [fill],
.brand-dark .brand .btn:hover .svg-icon svg g [fill] {
  fill: #ffffff !important;
}

.tingtong .btn.btn-light-primary {
  color: #2B296D !important;
}

.tingtong .btn.btn-light-primary i {
  color: #2B296D !important;
}

.tingtong .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-primary:focus:not(.btn-text),
.btn.btn-light-primary.focus:not(.btn-text) {
  background-color: #2B296D !important;
  color: #ffffff !important;
}

.tingtong .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.btn.btn-light-primary:focus:not(.btn-text) i,
.btn.btn-light-primary.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.tingtong .checkbox>input:checked~span {
  background-color: #00a79e !important;
}

.tingtong .btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  color: #ffffff !important;
  background-color: #150175 !important;
  border-color: #150175 !important;
}

.tingtong .nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line .nav-link.active,
.nav.nav-tabs.nav-tabs-line .show>.nav-link {
  border-bottom: 1px solid #150175 !important;
}

.tingtong .pulse.pulse-primary .pulse-ring {
  border-color: #150175 !important;
}

// --- TINGTONG --- 

// --- APLUS ---
.text-aplus {
  color: #fc7314 !important;
}

.text-hover-aplus {
  transition: $transition-link;

  &:hover {
    transition: $transition-link;
    color: #f9934e !important;

    i {
      color: #fc7314 !important;
    }

    .svg-icon {
      @include svg-icon-color(#fc7314);
    }
  }
}

.btn-aplus {
  color: #ffffff !important;
  background-color: #fc7314 !important;
  border-color: #fc7314 !important;
}

.aplus a {
  color: #fc7314;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #929292;
  }
}

.aplus .carousel-indicators {
  margin-bottom: -50px;
}

.aplus .carousel-indicators li {
  background-color: #f9934e;
  width: 60px;
  height: 8px;
  border-radius: 0.42rem !important;
}

.aplus .carousel-indicators .active {
  background-color: #fc7314;
}

.aplus .aside {
  background-color: #929292;
}

.aplus .aside-menu {
  background-color: #929292;
}

.aplus .brand {
  background-color: #fc7314 !important;
}

.aplus.aside-menu .menu-link:hover,
.aplus.aside-menu .menu-link:active,
.aplus.aside-menu .menu-item.menu-item-active .menu-link,
.aplus.aside-menu .menu-item.menu-item-open .menu-link {
  background-color: #3f3f3f !important;
}

.aplus .bg-primary {
  background-color: #929292 !important;
}

.aplus .border-primary {
  border-color: #fc7314 !important;
}

.aplus .nav-text {
  color: #f9934e !important;
}

.aplus .navi .navi-item .navi-link:hover {
  color: #f9934e !important;
}

.aplus .navi .navi-item .navi-link:hover .navi-icon i {
  color: #f9934e !important;
}

.aplus .navi .navi-item .navi-link:hover .navi-text {
  color: #f9934e !important;
}

.aplus .dropzone-primary {
  border-color: #fc7314 !important;
}

.aplus .text-primary {
  color: #fc7314 !important;
}

.aplus a.text-hover-primary:hover,
.text-hover-primary:hover {
  color: #f9934e !important;
}

.aplus .accordion {
  .card {
    overflow: visible !important;

    &:last-child {
      margin-bottom: 0;
    }

    .card-header {
      cursor: pointer;
      margin: 0;
      border-bottom: 0;
      padding: 0;
      background-color: $gray-100;

      .card-title {
        padding: get($accordion-config, card-spacer-y) get($accordion-config, card-spacer-x);
        margin: 0;
        font-size: get($accordion-config, card-title-font-size);
        font-weight: 500;
        color: #fc7314;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: $transition;

        >i {
          margin-right: 1rem;
          font-size: get($accordion-config, card-icon-font-size);
          color: #fc7314;
          transition: $transition;
        }

        .card-label {
          flex-grow: 1;
        }

        &.collapsed {
          color: $dark-75;
          transition: $transition;

          >i {
            color: $text-muted;
            transition: $transition;
          }
        }

        &:after {
          right: 1rem;
        }
      }

      .card-text {
        flex-grow: 1;
      }
    }

    .card-body {
      padding: get($accordion-config, card-spacer-y) get($accordion-config, card-spacer-x);
      font-size: 1rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-outline {
    .card {
      background-color: transparent;
    }
  }
}

.aplus .btn-primary {
  background-color: #fc7314 !important;
  border-color: #fc7314 !important;
}

.aplus .btn-primary:hover {
  background-color: #f9934e !important;
  border-color: #f9934e !important;
}

.aplus .btn-primary.active {
  background-color: #f9934e !important;
  border-color: #f9934e !important;
}

.aplus .splash-spinner .path {
  stroke: #fc7314 !important;
}

.aplus.bg-card-report {
  background-color: #fc7314 !important
}

.aplus .spinner.spinner-primary:before {
  border: 2px solid #f9934e;
}

.aplus .modal .modal-header {
  //background-color: #fc7314 !important;
  background-color: #3f3f3f !important;
}

.aplus .nav-icon i {
  color: #fc7314 !important;
}

.aplus .menu-item.menu-item-active>.menu-link .menu-text {
  color: #fc7314 !important;
}

.aplus .menu-item:hover>.menu-link .menu-text {
  color: #fc7314 !important;
}

.aplus .nav.nav-tabs.nav-tabs-line .nav-link.active {
  border-bottom: 1px solid #fc7314;
}

.aplus .nav .show>.nav-link,
.nav .nav-link:hover,
.nav .nav-link.active {
  color: #f9934e !important;
}

.aplus .svg-icon.svg-icon-primary svg g [fill] {
  fill: #f9934e !important;
}

.aplus .brand-dark .brand .btn.active .svg-icon svg g [fill],
.brand-dark .brand .btn:hover .svg-icon svg g [fill] {
  fill: #ffffff !important;
}

.aplus .btn.btn-light-primary {
  color: #fc7314 !important;
}

.aplus .btn.btn-light-primary i {
  color: #fc7314 !important;
}

.aplus .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-primary:focus:not(.btn-text),
.btn.btn-light-primary.focus:not(.btn-text) {
  background-color: #fc7314 !important;
  color: #ffffff !important;
}

.aplus .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.btn.btn-light-primary:focus:not(.btn-text) i,
.btn.btn-light-primary.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.aplus .checkbox>input:checked~span {
  background-color: #f9934e !important;
}

.aplus .btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  color: #ffffff !important;
  background-color: #f9934e !important;
  border-color: #f9934e !important;
}

.aplus .nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line .nav-link.active,
.nav.nav-tabs.nav-tabs-line .show>.nav-link {
  border-bottom: 1px solid #f9934e !important;
}

.aplus .pulse.pulse-primary .pulse-ring {
  border-color: #f9934e !important;
}

// --- APLUS ---

// --- BIZCITI ---
.text-bizciti {
  color: #253e92 !important;
}

.text-hover-bizciti {
  transition: $transition-link;

  &:hover {
    transition: $transition-link;
    color: #4f90cd !important;

    i {
      color: #253e92 !important;
    }

    .svg-icon {
      @include svg-icon-color(#253e92);
    }
  }
}

.btn-bizciti {
  color: #ffffff !important;
  background-color: #253e92 !important;
  border-color: #253e92 !important;
}

.bizciti a {
  color: #253e92;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #4f90cd;
  }
}

.bizciti .carousel-indicators {
  margin-bottom: -50px;
}

.bizciti .carousel-indicators li {
  background-color: #4f90cd;
  width: 60px;
  height: 8px;
  border-radius: 0.42rem !important;
}

.bizciti .carousel-indicators .active {
  background-color: #253e92;
}

.bizciti .aside {
  background-color: #4f90cd;
}

.bizciti .aside-menu {
  background-color: #4f90cd;
}

.bizciti .brand {
  background-color: #253e92 !important;
}

.bizciti.aside-menu .menu-link:hover,
.bizciti.aside-menu .menu-link:active,
.bizciti.aside-menu .menu-item.menu-item-active .menu-link,
.bizciti.aside-menu .menu-item.menu-item-open .menu-link {
  background-color: #253e92 !important;
}

.bizciti.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading,
.bizciti.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link,
.bizciti.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link:hover {
  background-color: #4f90cd !important;
}

.bizciti .bg-primary {
  background-color: #4f90cd !important;
}

.bizciti .border-primary {
  border-color: #253e92 !important;
}

.bizciti .nav-text {
  color: #4f90cd !important;
}

.bizciti .navi .navi-item .navi-link:hover {
  color: #4f90cd !important;
}

.bizciti .navi .navi-item .navi-link:hover .navi-icon i {
  color: #4f90cd !important;
}

.bizciti .navi .navi-item .navi-link:hover .navi-text {
  color: #4f90cd !important;
}

.bizciti .dropzone-primary {
  border-color: #253e92 !important;
}

.bizciti .bg-card-report {
  background-color: #253e92 !important;
}

.bizciti .text-primary {
  color: #253e92 !important;
}

.bizciti a.text-hover-primary:hover,
.text-hover-primary:hover {
  color: #4f90cd !important;
}

.bizciti .accordion {
  .card {
    overflow: visible !important;

    &:last-child {
      margin-bottom: 0;
    }

    .card-header {
      cursor: pointer;
      margin: 0;
      border-bottom: 0;
      padding: 0;
      background-color: $gray-100;

      .card-title {
        padding: get($accordion-config, card-spacer-y) get($accordion-config, card-spacer-x);
        margin: 0;
        font-size: get($accordion-config, card-title-font-size);
        font-weight: 500;
        color: #253e92;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: $transition;

        >i {
          margin-right: 1rem;
          font-size: get($accordion-config, card-icon-font-size);
          color: #253e92;
          transition: $transition;
        }

        .card-label {
          flex-grow: 1;
        }

        &.collapsed {
          color: $dark-75;
          transition: $transition;

          >i {
            color: $text-muted;
            transition: $transition;
          }
        }

        &:after {
          right: 1rem;
        }
      }

      .card-text {
        flex-grow: 1;
      }
    }

    .card-body {
      padding: get($accordion-config, card-spacer-y) get($accordion-config, card-spacer-x);
      font-size: 1rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-outline {
    .card {
      background-color: transparent;
    }
  }
}

.bizciti .btn-primary {
  background-color: #253e92 !important;
  border-color: #253e92 !important;
}

.bizciti .btn-primary:hover {
  background-color: #4f90cd !important;
  border-color: #4f90cd !important;
}

.bizciti .btn-primary.active {
  background-color: #4f90cd !important;
  border-color: #4f90cd !important;
}

.bizciti .splash-spinner .path {
  stroke: #253e92 !important;
}

.bizciti .spinner.spinner-primary:before {
  border: 2px solid #4f90cd;
}

.bizciti .modal .modal-header {
  //background-color: #253e92 !important;
  background-color: #253e92 !important;
}

.bizciti .nav-icon i {
  color: #253e92 !important;
}

.bizciti .header-menu .menu-item.menu-item-active>.menu-link .menu-text,
.bizciti .header-menu .menu-item>.menu-link .menu-text:hover,
.bizciti .header-menu .menu-item>.menu-link .menu-text:active {
  color: #253e92 !important;
}

.bizciti.aside-menu .menu-item:hover>.menu-link .menu-text {
  color: #ffffff !important;
}

.bizciti .nav.nav-tabs.nav-tabs-line .nav-link.active {
  border-bottom: 1px solid #253e92;
}

.bizciti .nav .show>.nav-link,
.nav .nav-link:hover,
.nav .nav-link.active {
  color: #4f90cd !important;
}

.bizciti .svg-icon.svg-icon-primary svg g [fill] {
  fill: #4f90cd !important;
}

.bizciti .brand-dark .brand .btn.active .svg-icon svg g [fill],
.brand-dark .brand .btn:hover .svg-icon svg g [fill] {
  fill: #ffffff !important;
}

.bizciti .btn.btn-light-primary {
  color: #253e92 !important;
}

.bizciti .btn.btn-light-primary i {
  color: #253e92 !important;
}

.bizciti .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-primary:focus:not(.btn-text),
.btn.btn-light-primary.focus:not(.btn-text) {
  background-color: #253e92 !important;
  color: #ffffff !important;
}

.bizciti .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.btn.btn-light-primary:focus:not(.btn-text) i,
.btn.btn-light-primary.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.bizciti .checkbox>input:checked~span {
  background-color: #4f90cd !important;
}

.bizciti .btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  color: #ffffff !important;
  background-color: #4f90cd !important;
  border-color: #4f90cd !important;
}

.bizciti .nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line .nav-link.active,
.nav.nav-tabs.nav-tabs-line .show>.nav-link {
  border-bottom: 1px solid #4f90cd !important;
}

.bizciti .pulse.pulse-primary .pulse-ring {
  border-color: #4f90cd !important;
}

// --- BIZCITI ---