.datepicker {
	width: 265px;
	padding: 10px;
	border-radius: 0.42rem;
  }
  .datepicker.datepicker-orient-top {
	margin-top: 8px;
  }
  .datepicker table {
	width: 100%;
  }
  .datepicker td,
  .datepicker th {
	font-size: 1rem;
	font-weight: regular;
	width: 33px;
	height: 33px;
	border-radius: 0.42rem;
  }
  .datepicker thead th {
	color: #3F4254;
  }
  .datepicker thead th.prev, .datepicker thead th.datepicker-switch, .datepicker thead th.next {
	font-weight: 500;
	color: #3F4254;
  }
  .datepicker thead th.prev i, .datepicker thead th.datepicker-switch i, .datepicker thead th.next i {
	font-size: 1.2rem;
	color: #7E8299;
  }
  .datepicker thead th.prev i:before, .datepicker thead th.datepicker-switch i:before, .datepicker thead th.next i:before {
	line-height: 0;
	vertical-align: middle;
  }
  .datepicker thead th.prev:hover, .datepicker thead th.datepicker-switch:hover, .datepicker thead th.next:hover {
	background: #F3F6F9 !important;
  }
  .datepicker thead th.dow {
	color: #3F4254;
	font-weight: 600;
  }
  .datepicker tbody tr > td {
	width: 35px;
	height: 35px;
  }
  .datepicker tbody tr > td.day {
	color: #7E8299;
	font-weight: 400;
  }
  .datepicker tbody tr > td.day:hover {
	background: #F3F6F9;
	color: #3F4254;
  }
  .datepicker tbody tr > td.day.old {
	color: #7E8299;
  }
  .datepicker tbody tr > td.day.new {
	color: #3F4254;
  }
  .datepicker tbody tr > td.day.selected, .datepicker tbody tr > td.day.selected:hover, .datepicker tbody tr > td.day.active, .datepicker tbody tr > td.day.active:hover {
	background: #3699FF;
	color: #ffffff;
  }
  .datepicker tbody tr > td.day.today {
	position: relative;
	background: #E1F0FF !important;
	color: #3699FF !important;
  }
  .datepicker tbody tr > td.day.today:before {
	content: "";
	display: inline-block;
	border: solid transparent;
	border-width: 0 0 7px 7px;
	border-bottom-color: #3699FF;
	border-top-color: #3699FF;
	position: absolute;
	bottom: 4px;
	right: 4px;
  }
  .datepicker tbody tr > td.day.range {
	background: #F3F6F9;
  }
  .datepicker tbody tr > td span.year,
  .datepicker tbody tr > td span.hour,
  .datepicker tbody tr > td span.minute,
  .datepicker tbody tr > td span.month {
	color: #7E8299;
  }
  .datepicker tbody tr > td span.year:hover,
  .datepicker tbody tr > td span.hour:hover,
  .datepicker tbody tr > td span.minute:hover,
  .datepicker tbody tr > td span.month:hover {
	background: #F3F6F9;
  }
  .datepicker tbody tr > td span.year.focused, .datepicker tbody tr > td span.year.focused:hover, .datepicker tbody tr > td span.year.active:hover, .datepicker tbody tr > td span.year.active.focused:hover, .datepicker tbody tr > td span.year.active,
  .datepicker tbody tr > td span.hour.focused,
  .datepicker tbody tr > td span.hour.focused:hover,
  .datepicker tbody tr > td span.hour.active:hover,
  .datepicker tbody tr > td span.hour.active.focused:hover,
  .datepicker tbody tr > td span.hour.active,
  .datepicker tbody tr > td span.minute.focused,
  .datepicker tbody tr > td span.minute.focused:hover,
  .datepicker tbody tr > td span.minute.active:hover,
  .datepicker tbody tr > td span.minute.active.focused:hover,
  .datepicker tbody tr > td span.minute.active,
  .datepicker tbody tr > td span.month.focused,
  .datepicker tbody tr > td span.month.focused:hover,
  .datepicker tbody tr > td span.month.active:hover,
  .datepicker tbody tr > td span.month.active.focused:hover,
  .datepicker tbody tr > td span.month.active {
	background: #3699FF;
	color: #ffffff;
  }
  .datepicker tfoot tr > th {
	width: 35px;
	height: 35px;
  }
  .datepicker tfoot tr > th.today, .datepicker tfoot tr > th.clear {
	border-radius: 0.42rem;
	font-weight: 500;
  }
  .datepicker tfoot tr > th.today:hover, .datepicker tfoot tr > th.clear:hover {
	background: #EBEDF3;
  }
  .datepicker.datepicker-inline {
	border: 1px solid #EBEDF3;
  }
  
  .input-daterange .input-group-addon {
	min-width: 44px;
  }
  .input-daterange input {
	text-align: left;
  }
  .input-daterange .input-group-append .input-group-text {
	border-right: 0;
  }
  
  .daterangepicker {
	padding: 0;
	margin: 0;
	width: auto;
	-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
	box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
	border-radius: 0.42rem;
	border: 0;
	font-family: Poppins, Helvetica, "sans-serif";
	z-index: 96;
  }
  .modal-open .daterangepicker {
	z-index: 1051;
  }
  .daterangepicker:after, .daterangepicker:before {
	display: none;
  }
  .daterangepicker .ranges ul {
	padding: 1rem 0;
	width: 175px;
  }
  .daterangepicker .ranges li {
	padding: 0.7rem 1.75rem;
	font-weight: 500;
	font-size: 1rem;
	color: #7E8299;
	-webkit-transition: color 0.3s ease;
	transition: color 0.3s ease;
  }
  .daterangepicker .ranges li:hover, .daterangepicker .ranges li.active {
	background-color: #F3F6F9;
	color: #3699FF;
	-webkit-transition: color 0.3s ease;
	transition: color 0.3s ease;
  }
  .daterangepicker.show-calendar .ranges {
	border-right: 1px solid #EBEDF3;
	margin-top: 0;
	height: 297px;
  }
  .daterangepicker.show-ranges .drp-calendar.left {
	border-left: 0;
  }
  .daterangepicker .drp-buttons {
	padding: 1rem 1.75rem;
	border-top: 1px solid #EBEDF3;
  }
  .daterangepicker .drp-buttons .btn {
	font-size: 0.9rem;
	font-weight: 500;
	padding: 0.5rem 1rem;
	border-radius: 0.42rem;
  }
  .daterangepicker .drp-selected {
	font-size: 0.9rem;
  }
  .daterangepicker .drp-calendar.left, .daterangepicker .drp-calendar.right {
	padding: 1rem 1rem;
  }
  .daterangepicker .drp-calendar.left {
	border-left: 0 !important;
  }
  .daterangepicker .drp-calendar th,
  .daterangepicker .drp-calendar td {
	font-size: 1rem;
	font-weight: regular;
	width: 33px;
	height: 33px;
  }
  .daterangepicker .drp-calendar th {
	font-weight: 500;
	color: #3F4254;
  }
  .daterangepicker .drp-calendar th.month {
	font-weight: 500;
	color: #3F4254;
  }
  .daterangepicker .drp-calendar th.next span, .daterangepicker .drp-calendar th.prev span {
	border-width: 0 1px 1px 0;
	border-color: #7E8299;
  }
  .daterangepicker .drp-calendar th.next span {
	margin-right: 1px;
  }
  .daterangepicker .drp-calendar th.prev span {
	margin-left: 1px;
  }
  .daterangepicker .drp-calendar td {
	color: #7E8299;
  }
  .daterangepicker .drp-calendar td:hover {
	background-color: #F3F6F9;
  }
  .daterangepicker .drp-calendar td.available.off {
	color: #B5B5C3;
  }
  .daterangepicker .drp-calendar td.active {
	background-color: #3699FF !important;
	color: #FFFFFF !important;
	border-radius: 0.42rem;
  }
  .daterangepicker .drp-calendar td.active.start-date {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
  }
  .daterangepicker .drp-calendar td.active.end-date {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
  }
  .daterangepicker .drp-calendar td.active.start-date.end-date {
	border-radius: 0.42rem;
  }
  .daterangepicker .drp-calendar td.today, .daterangepicker .drp-calendar td.today.active {
	border-radius: 0.42rem;
	background: rgba(54, 153, 255, 0.12) !important;
	color: #3699FF !important;
  }
  .daterangepicker .drp-calendar td.in-range.available:not(.active):not(.off):not(.today) {
	background-color: #F3F6F9;
	color: #3F4254;
  }
  .daterangepicker select {
	border-radius: 0.42rem;
	background: transparent !important;
	border-color: #E4E6EF !important;
	color: #3F4254 !important;
  }
  
  @media (min-width: 730px) {
	.daterangepicker.show-calendar .ranges {
	  height: 297px !important;
	}
  }
  @media (max-width: 767.98px) {
	.daterangepicker.show-calendar .ranges {
	  height: 245px;
	}
  }
  .bootstrap-datetimepicker-widget {
	border: 1px solid #EBEDF3;
	border-radius: 0.42rem;
  }
  .bootstrap-datetimepicker-widget.dropdown-menu {
	border: 0;
	width: 265px !important;
	padding: 0;
  }
  .bootstrap-datetimepicker-widget .datepicker {
	width: 100%;
	padding: 10px;
  }
  .bootstrap-datetimepicker-widget .datepicker table {
	width: 100%;
  }
  .bootstrap-datetimepicker-widget .datepicker table thead th {
	display: table-cell;
  }
  .bootstrap-datetimepicker-widget .datepicker table thead th.picker-switch {
	color: #3F4254;
	font-weight: 500;
	display: table-cell;
	font-size: 1rem;
  }
  .bootstrap-datetimepicker-widget .datepicker table thead th.picker-switch:hover {
	color: #3699FF;
	background: #F3F6F9 !important;
  }
  .bootstrap-datetimepicker-widget .datepicker table thead th.prev span, .bootstrap-datetimepicker-widget .datepicker table thead th.next span {
	font-size: 0.8rem;
	color: #7E8299;
  }
  .bootstrap-datetimepicker-widget .datepicker table thead th.prev:hover span, .bootstrap-datetimepicker-widget .datepicker table thead th.next:hover span {
	color: #3699FF;
  }
  .bootstrap-datetimepicker-widget .datepicker table thead th.disabled {
	color: #B5B5C3;
	cursor: not-allowed;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tr td,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tr th {
	font-size: 1rem;
	width: 35px;
	height: 35px;
	padding: 0;
	font-weight: regular;
	vertical-align: middle;
	text-align: center;
	border-radius: 0.42rem;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td {
	color: #7E8299;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td:hover {
	background: #F3F6F9;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td.old {
	color: #7E8299;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td.new {
	color: #3F4254;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td:focus, .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td.active {
	background: #3699FF !important;
	color: #ffffff !important;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td.selected {
	background: #E1F0FF;
	color: #3699FF;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td.disabled {
	color: #B5B5C3;
	cursor: not-allowed;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td.today {
	position: relative;
	background: #E1F0FF !important;
	color: #3699FF !important;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr > td.today:before {
	content: "";
	display: inline-block;
	border: solid transparent;
	border-width: 0 0 7px 7px;
	border-bottom-color: #3699FF;
	border-top-color: #3699FF;
	position: absolute;
	bottom: 4px;
	right: 4px;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th {
	font-size: 1rem;
	width: 35px;
	height: 35px;
	padding: 0;
	font-weight: regular;
	vertical-align: middle;
	text-align: center;
	border-radius: 0.42rem;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span {
	color: #7E8299;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span:hover,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span:hover,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span:hover,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span:hover,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span:hover,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span:hover {
	background: #F3F6F9;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span.old,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span.old,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span.old,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span.old,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span.old,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span.old {
	color: #7E8299;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span.new,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span.new,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span.new,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span.new,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span.new,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span.new {
	color: #3F4254;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span:focus, .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span.active,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span:focus,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span.active,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span:focus,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span.active,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span:focus,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span.active,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span:focus,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span.active,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span:focus,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span.active {
	background: #3699FF !important;
	color: #ffffff !important;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span.selected,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span.selected,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span.selected,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span.selected,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span.selected,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span.selected {
	background: #E1F0FF;
	color: #3699FF;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span.today,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span.today,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span.today,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span.today,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span.today,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span.today {
	position: relative;
	background: #E1F0FF !important;
	color: #3699FF !important;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span.today:before,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span.today:before,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span.today:before,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span.today:before,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span.today:before,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span.today:before {
	content: "";
	display: inline-block;
	border: solid transparent;
	border-width: 0 0 7px 7px;
	border-bottom-color: #3699FF !important;
	border-top-color: #3699FF !important;
	position: absolute;
	bottom: 4px;
	right: 4px;
  }
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr td span.disabled,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-months table tr th span.disabled,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr td span.disabled,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-years table tr th span.disabled,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr td span.disabled,
  .bootstrap-datetimepicker-widget .datepicker .datepicker-decades table tr th span.disabled {
	color: #B5B5C3;
	cursor: not-allowed;
  }
  .bootstrap-datetimepicker-widget .timepicker {
	padding: 10px;
  }
  .bootstrap-datetimepicker-widget .timepicker .timepicker-picker table td {
	width: auto;
	height: auto;
	text-align: center;
	vertical-align: middle;
	padding: 0;
	line-height: 35px;
  }
  .bootstrap-datetimepicker-widget .timepicker .timepicker-picker table td a {
	padding: 0;
  }
  .bootstrap-datetimepicker-widget .timepicker .timepicker-picker table td span {
	-ms-flex-line-pack: center;
	align-content: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 35px;
	width: 35px;
	font-size: 0.8rem;
	color: #7E8299;
  }
  .bootstrap-datetimepicker-widget .timepicker .timepicker-picker table td .timepicker-minute,
  .bootstrap-datetimepicker-widget .timepicker .timepicker-picker table td .timepicker-hour {
	font-size: 1rem;
	color: #7E8299;
	font-weight: 400;
  }
  .bootstrap-datetimepicker-widget .timepicker .timepicker-picker table td:hover span {
	background: #F3F6F9;
	color: #3699FF;
  }
  .bootstrap-datetimepicker-widget .timepicker .timepicker-minutes table,
  .bootstrap-datetimepicker-widget .timepicker .timepicker-hours table {
	width: auto;
	margin: 0 auto;
  }
  .bootstrap-datetimepicker-widget .timepicker .timepicker-minutes table tr td,
  .bootstrap-datetimepicker-widget .timepicker .timepicker-hours table tr td {
	height: 35px;
	width: 35px;
	line-height: 35px;
	font-size: 1rem;
	font-weight: 400;
	color: #7E8299;
  }
  .bootstrap-datetimepicker-widget .timepicker .timepicker-minutes table tr td:hover,
  .bootstrap-datetimepicker-widget .timepicker .timepicker-hours table tr td:hover {
	background: #F3F6F9;
	color: #3699FF;
  }
  .bootstrap-datetimepicker-widget .picker-switch.accordion-toggle {
	padding: 10px;
  }
  .bootstrap-datetimepicker-widget .picker-switch.accordion-toggle table {
	padding: 0;
  }
  .bootstrap-datetimepicker-widget .picker-switch.accordion-toggle table td {
	padding: 0;
	height: 35px;
  }
  .bootstrap-datetimepicker-widget .picker-switch.accordion-toggle table td a {
	padding: 0;
	color: #3F4254;
	font-weight: 500;
  }
  .bootstrap-datetimepicker-widget .picker-switch.accordion-toggle table td a span {
	font-size: 1.1rem;
	color: #7E8299;
  }
  .bootstrap-datetimepicker-widget .picker-switch.accordion-toggle table td:hover {
	background: #F3F6F9 !important;
  }
  .bootstrap-datetimepicker-widget .picker-switch.accordion-toggle table td:hover a span {
	background: transparent;
	color: #3699FF;
  }
  .bootstrap-datetimepicker-widget .collapse.show ~ .picker-switch.accordion-toggle {
	padding-top: 0;
  }
  .bootstrap-datetimepicker-widget .picker-switch.accordion-toggle ~ .collapse.show .timepicker {
	padding-top: 0;
  }
