//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants

// Primary colors

$primary: #009c7e; // Bootstrap variable
$aplus: #fc7314; // VIP variable
$primary-hover: #00bc7e; // Custom variable
$primary-light: #e1f0ff; // Custom variable
$primary-inverse: #ffffff; // Custom variable
// Success colors
$success: #39aea9 !default; // Bootstrap variable
$success-hover: #0bb7af !default; // Custom variable
$success-light: #c9f7f5 !default; // Custom variable
$success-inverse: #ffffff !default; // Custom variable

$primary-menu-link-bg: #007c7e; // Custom variable
$primary-menu-link-text: #ffffff; // Custom variable
$primary-menu-icon: #ffffff; // Custom variable
$layout-themes-dark: #009c7e; // Custom variable
$layout-themes-light: #ffffff; // Custom variable;

$card-spacer-y: 0.5rem !default; // Custom variable;
$card-spacer-x: 0.75rem !default; // Custom variable;

$card-header-spacer-y: 0.5rem !default; // Custom variable
$card-header-height: 30px !default; // Custom variable
$card-sticky-header-height: 30px !default; // Custom variable

$input-btn-padding-y-sm: 0.185rem !default; // Custom variable
$input-btn-padding-x-sm: 0.35rem !default; // Custom variable

$form-group-margin-bottom: 0.5rem !default; // Custom variable

$table-compact-head-letter-spacing: 0.1rem !default; // Custom variable

$table-head-padding-top: 0.3rem; // Custom variable
$table-head-padding-bottom: 0.3rem; // Custom variable

$btn-secondary-color: #ffffff !default;
$btn-secondary-hover-bg-color: #00bc7e !default;

$table-cell-padding-sm: 0.15rem !default; // Custom variable
// Black color
$black: #000000 !default;

// White color
$white: #ffffff !default;
$table-compact-head-color: $black !default; // Custom variable

// Border Radiues
// $border-radius-sm:            						.0rem !default;
// $border-radius:               						.0rem !default;
// $border-radius-lg:            						.0rem !default;
// $border-radius-xl:                                  .0rem !default; // Custom variable

$modal-header-padding-y: 0.5rem !default;
$modal-header-padding-x: 1.75rem !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg: $font-size-base * 1.08 !default; // 14.04px
$font-size-sm: $font-size-base * 0.825 !default; // 12.025px
$font-size-xs: $font-size-base * 0.8 !default; // 10.4px

$form-label-font-size: 0.95rem !default; // Custom variable

// SVG Icon Sizes
$svg-icon-sizes: (
    sm: 1.25rem,
    md: 1.5rem,
    lg: 1.75rem,
    xl: 2rem,
    xxl: 2.25rem,
    1x: 1rem,
    2x: 2rem,
    3x: 3rem,
    4x: 4rem,
    5x: 5rem,
    6x: 6rem,
    7x: 7rem,
    8x: 8rem,
    9x: 9rem,
    10x: 10rem,
    full: 100%,
);

$grid-gutter-width: 15px !default;
