//
$white: #ffffff;
$dark: #131313;
$snow: #f7f7f7;
$sea: #007bff;
$dropzone-border: #c6ccd6;
$dropzone-background: #f9f9fb;
$file-text-color: #8c96a8;
$file-icon-border: lighten(#8c96a8, 15);

// Layout Custom
.content {
  padding: 0px !important;
}

// Commons
.custom-is-valid {
  border-color: #00cfa7;
}

.text-online {
  color: #31a24c;
}

.text-offline {
  color: #c0c0c0;
}

.hidden {
  visibility: hidden;
}

.required {
  color: #ff0000;
}

.checkbox-center {
  vertical-align: middle;
}

.border-left-3 {
  border-left: 3px solid $primary;
}

.width-per-100 {
  width: 100% !important;
}

.modal {

  // Header
  .modal-header {
    padding: 0.25rem 1.75rem;
    background-color: $primary;

    .modal-title {
      color: $white;
      font-size: 1.05rem !important;
    }
  }

  .modal-body {
    padding: 0.5rem 1.75rem;
  }

  .modal-footer {
    padding: 0.25rem 1.5rem;
  }

  .modal-form {
    padding: 0.25rem 10rem;
  }
}

.form-group {
  label {
    font-size: $form-label-font-size;
    font-weight: $form-label-font-weight;
    color: $dark-75;
    margin-bottom: 0rem;
  }

  label.required::after {
    content: "*";
    margin-left: 4px;
    color: red;
  }
}

// Table
.filter label {
  width: 100%;
}

.filter-label {
  width: 100%;
  margin-bottom: 0rem;
}

.filter.number-filter .filter-label,
.filter.date-filter .filter-label {
  width: max-content;
  margin-bottom: 0rem;
}

fieldset.group-border {
  border: 1px solid $gray-200 !important;
  padding: 0.5em 1em !important;
  margin: 0 0 0.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px $gray-200;
  box-shadow: 0px 0px 0px 0px $gray-200;
}

legend.group-border {
  font-size: 1em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

.section-units {
  background-color: $success-light;
  color: $success !important;
}

.section-contracts {
  background-color: $info-light;
  color: $info !important;
}

.section-services {
  background-color: $warning-light;
  color: $warning !important;
}

.section-invoices {
  background-color: $danger-light;
  color: $danger !important;
}

table td p {
  margin: 0;
}

table td,
table th {
  white-space: nowrap;
  overflow: hidden;
}

.table-desk th {
  text-align: center;
  padding: 0.3rem;
}

.table-desk .form-control {
  min-width: 65px;
}

.width-40 {
  width: 40px;
}

.react-bootstrap-table {
  overflow-x: auto;

  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }

    &.expand-cell-header {
      width: 10px;
    }

    &.selection-cell-header {
      width: 10px;
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }

  .table.table-head-custom {
    tr {
      td {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  &.table-scrollable {

    .table>tr,
    .table>td,
    .table>td>span {
      font-size: 11px !important;
    }
  }
}

.table-col-equal {
  table-layout: fixed;
  width: 100%;
}

.input-as-text {
  border: none;
  background: transparent;
  color: black;
}

.print-container {
  table {
    width: 100%;
  }

  hr {
    border-top: 1px dashed #000000;
  }

  .MsoNormal {
    font-family: "Times New Roman", Times, serif;
    font-size: 13px !important;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .MsoNormalHeader {
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .MsoNormalMargin {
    margin-right: 50px;
  }
}

.modal-full {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}

.table-invoice-services {
  input {
    min-width: 60px;
  }

  select {
    min-width: 95px;
  }

  input.money-field {
    min-width: 80px;
  }

  input[type="datetime-local"] {
    width: 155px;
  }

  tr,
  td,
  td>span {
    font-size: 12px;
    padding: 2px;
  }
}

.modal .modal-scroll {
  position: relative;
  overflow: auto;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 18px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.particles-container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  inset: 0px;
}

// Variables
$wizard-nav-width: 400px;
$wizard-nav-width-sm: 325px;

// Base
.wizard.wizard-2 {
  display: flex;

  // Nav
  .wizard-nav {
    padding: 0;
    flex: 0 0 $wizard-nav-width;
    width: $wizard-nav-width;
    max-width: 100%;

    // Steps
    .wizard-steps {
      display: flex;
      flex-direction: column;
      justify-content: center;

      // Step
      .wizard-step {
        padding: 0.75rem 1.5rem;
        position: relative;
        border-radius: 0.5rem;
        transition: $transition-link;

        .wizard-wrapper {
          display: flex;
        }

        .wizard-icon {
          font-size: 2.5rem;
          margin-right: 1.1rem;
          transition: $transition-link;

          i {
            color: $text-muted;
          }

          .svg-icon {
            @include svg-icon-color($text-muted);
          }
        }

        .wizard-label {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .wizard-title {
            color: $dark;
            font-weight: 500;
            font-size: 1.1rem;
          }

          .wizard-desc {
            color: $dark-50;
          }
        }

        .wizard-arrow {
          color: $dark-50;
          font-size: 1.25rem;

          &.last {
            display: none;
          }
        }

        &[data-wizard-state="current"] {
          background-color: $gray-100;
          transition: $transition-link;

          .wizard-icon {
            transition: $transition-link;

            i {
              color: $primary !important;
            }

            .svg-icon {
              @include svg-icon-color($primary, true);
            }
          }

          &:after {
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            content: " ";
            height: 0;
            width: 0;
            border: solid transparent;
            position: absolute;
            border-left-color: $gray-100;
            border-width: 1rem;
          }
        }
      }
    }
  }

  // Body
  .wizard-body {
    width: 100%;
  }
}

// Custom desktop range
@include media-breakpoint-between(lg, xl) {

  // Base
  .wizard.wizard-2 {

    // Nav
    .wizard-nav {
      flex: 0 0 $wizard-nav-width-sm;
      width: $wizard-nav-width-sm;
    }
  }
}

// Tablet and mobile mode
@include media-breakpoint-down(md) {
  .wizard.wizard-2 {
    display: block;

    // Nav
    .wizard-nav {
      width: 100%;
      border-bottom: 1px solid $border-color;

      .wizard-steps {
        .wizard-step {
          &[data-wizard-state="current"] {
            &:after {
              content: none;
            }
          }
        }
      }
    }

    // Body
    .wizard-body {
      width: 100%;
    }
  }
}

.table.table-head-custom .sub-header-custom th {
  font-size: 0.8em;
  font-weight: 500;
}

.table.table-preview th,
.table.table-preview td {
  padding-top: 2px;
  padding-bottom: 2px;
}

.table.table-preview-no-border,
.table.table-preview-no-border tr,
.table.table-preview-no-border th,
.table.table-preview-no-border td {
  border: none !important;
}

.table.table-preview-border,
.table.table-preview-border tr,
.table.table-preview-border th,
.table.table-preview-border td {
  border: 1px solid #dee2e6 !important;
}

.height-content {
  height: 84vh;
}

.scrollbar-infor {
  height: 37vh;
}

.scrollbar-friends {
  height: 700px;
}

.scrollbar-chat {
  height: 64vh;
}

.modal-margins {
  margin-left: 10px;
}

.text-over {
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
}

.textarea-resize {
  resize: none;
}

.border-cricle {
  width: 6px;
  height: 6px;
  background: blue;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  --border-radius: 3px;
  border-radius: 3px;
}

//

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

//

.mytext {
  border: 0;
  padding: 10px;
  background: whitesmoke;
}

.text {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.text>p:first-of-type {
  width: 100%;
  margin-top: 0;
  margin-bottom: auto;
  line-height: 13px;
  font-size: 12px;
}

.text>p:last-of-type {
  width: 100%;
  text-align: right;
  color: silver;
  margin-bottom: -7px;
  margin-top: auto;
}

.text-l {
  float: left;
  padding-right: 10px;
}

.text-r {
  float: right;
  padding-left: 10px;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  float: left;
  padding-right: 10px;
}

.macro {
  margin-top: 5px;
  width: 85%;
  border-radius: 5px;
  padding: 5px;
  display: flex;
}

.msj-rta {
  float: right;
  margin-right: 15px;
  background: #d9ffb5;
}

.msj {
  float: left;
  background: whitesmoke;
}

.text-min-width {
  min-width: 100px;
}

.frame {
  background: white;
  overflow: hidden;
  padding: 0;
}

.frame>div:last-of-type {
  position: absolute;
  bottom: 5px;
  width: 100%;
  display: flex;
}

// ul {
// 	width: 100%;
// 	list-style-type: none;
// 	padding: 18px;
// 	position: absolute;
// 	bottom: 32px;
// 	display: flex;
// 	flex-direction: column;
// }
.msj:before {
  width: 0;
  height: 0;
  content: "";
  top: -5px;
  left: -14px;
  position: relative;
  border-style: solid;
  border-width: 0 13px 13px 0;
  border-color: transparent whitesmoke transparent transparent;
}

.msj-rta:after {
  width: 0;
  height: 0;
  content: "";
  top: -5px;
  left: 14px;
  position: relative;
  border-style: solid;
  border-width: 13px 13px 0 0;
  border-color: #d9ffb5 transparent transparent transparent;
}

// ::-webkit-input-placeholder {
// 	/* Chrome/Opera/Safari */
// 	color: #d4d4d4;
// }
::-moz-placeholder {
  /* Firefox 19+ */
  color: #d4d4d4;
}

// :-ms-input-placeholder {
// 	/* IE 10+ */
// 	color: #d4d4d4;
// }
:-moz-placeholder {
  /* Firefox 18- */
  color: #d4d4d4;
}

.nav-link-cus {
  padding: 0.5rem 1rem !important;
  border-radius: 0rem !important;
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"]+label {
  padding: 0.365rem 0.35rem;
  font-size: 0.825rem;
  line-height: 1.35;
  border-radius: 0.42rem;
  background-color: #009c7e;
  color: #ffffff;

  i {
    color: #ffffff;
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #02b97e;
    border-color: #40c89c;
  }
}

.bg-multiColor {
  background-image: linear-gradient(to right, #39aea9, #a2d5ab);
}

.table-head-bootstrap-custom {
  background-color: #47c950;
  color: #ffffff;
  font-weight: bold;
}

.carousel-indicators {
  margin-bottom: -50px;
}

.carousel-indicators li {
  background-color: #00bc7e;
  width: 60px;
  height: 8px;
  border-radius: 0.42rem !important;
}

.carousel-indicators .active {
  background-color: #009c7e;
}

.cus-orgchart-container {
  margin: 10px 0px !important;
  height: 700px !important;
  border: 2px dashed #009c7e !important;
  border-radius: 5px;

  .rst__moveHandle,
  .rst__loadingHandle {
    background: #009c7e url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+) no-repeat center;
  }

  .orgchart ul li .oc-node .oc-heading {
    background-color: #009c7e;
  }

  .orgchart ul li .oc-node .oc-content {
    border: 1px solid #009c7e;
  }

  .orgchart>ul>li>ul li::before {
    border-top: 2px solid #009c7e;
  }

  .orgchart>ul>li>ul li>.oc-node::before {
    background-color: #009c7e;
  }

  .orgchart ul li .oc-node .oc-heading .oc-symbol::before {
    border-color: #009c7e;
  }

  .orgchart ul li .oc-node:not(:only-child)::after {
    background-color: #009c7e;
  }
}

.input-group .react-select {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.react-select .is-valid__control,
.react-select .is-invalid__control {
  border-color: #009c7e;
  box-shadow: none;
}

.react-select .is-valid__control {
  border-color: #007c7e;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23007c7e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.react-select .is-invalid__control {
  border-color: #f64e60;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.input-icon .is-valid {
  padding-right: 40px;
}

.input-icon .is-invalid {
  padding-right: 40px;
}

.drag-area {
  border: 2px dashed #fff;
  height: 500px;
  width: 700px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-area.active {
  border: 2px solid #fff;
}

.drag-area .icon {
  font-size: 100px;
  color: #fff;
}

.drag-area header {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
}

.drag-area span {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin: 10px 0 15px 0;
}

.drag-area button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #fff;
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.card-option:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}


.icon-up-down:hover {
  color: #009c7e;
}

@media print {
  .modal-body {
    width: auto;
    height: auto;
    overflow: visible !important;
  }

  body.modal-open {
    visibility: hidden;
  }

  body.modal-open .modal .modal-header,
  body.modal-open .modal .modal-body {
    visibility: visible;
  }

  .modal-footer {
    visibility: hidden;
  }

  .modal-header {
    visibility: hidden;
  }
}

@media (min-width: 992px) {
  .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-heading {
    padding: 15px;
  }
}

.container-animate-css-grid {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(2rem, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 5px;
    grid-auto-flow: dense;
  }

  .grid-template-columns {
    grid-template-columns: repeat(auto-fill, minmax(2rem, 1fr));
  }

  .grid-grap {
    grid-template-columns: repeat(auto-fill, minmax(2rem, 1fr));
  }

  .card--expanded {
    grid-column: span 2;
    grid-row: span 2;
  }

  .card {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .card>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card--1 {
    background-color: #366f6e;
  }

  .card--2 {
    background-color: #79ac91;
  }

  .card--3 {
    background-color: #e2b060;
  }

  .card--4 {
    background-color: #e86448;
  }

  .card--5 {
    background-color: #dc5263;
  }
}

.text-reset {
  text-decoration: none;
}

.text-reset:hover {
  text-decoration: underline;
}

.menu-inner-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 100%;
  grid-column-gap: 5px;
  padding: 0 15px !important;
  border-right: solid 1px #eee;
}

.menu-link-grid {
  padding: 12px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
  border: 1px solid transparent;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  transition: 0.1s ease-in-out;
}

.menu-link-grid img {
  width: 40px;
  height: 40px;
}

.menu-link-grid>span {
  display: block;
  white-space: nowrap;
  font-size: 13px;
  margin-top: 15px;
  line-height: 1;
}

.form-control-month,
.form-control-year {
  // width: 30px !important;
  padding: 0.5rem 0.5rem !important;
  text-align: center !important;
}

// Tabs
.nav.nav-tabs {
  &.nav-tabs-line {
    .nav-link {
      border: 0;
      border-bottom: 1px solid transparent;
      transition: $transition-link;
      padding: 0.5rem 0;
      margin: 0 0.85rem;
    }
  }
}

.word-break {
  word-break: break-all;
}

.text-italic {
  color: black;
  font-style: italic;
}

.overflow-wrap {
  overflow-wrap: anywhere;
}

.carousel .carousel-item {
  transition-duration: 3s;
}

@media screen and (min-width: 480px) {
  .abcd {
    max-width: 100%;
  }
}

.bg-empty {
  background-color: #00b14f;
}

.label.label-empty {
  background-color: #00b14f;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.input-group-text-table {
  padding: 0.1rem 0.3rem;
  font-size: 0.85rem;
}

.input-text-end {
  display: block;
  padding: 10px 18px 10px 20px;
}

.icon-text-end {
  position: absolute;
  display: block;
  right: 5px;
  top: 2px;
  z-index: 9;
}

.bg-red-secondary {
  background: linear-gradient(to right,
      #ffeace 0%,
      #ffeace 20%,
      #f5f7f6 20%,
      #f5f7f6 80%);
}

.card-color-boder {
  border-color: #009c7e;
}

.border-card-unit {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
  border-color: #f0faf1 !important;
  border-radius: 8px
}

@each $color,
$value in $theme-colors {
  .border-card-unit-#{$color} {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
    border-radius: 8px;
    border-color: $value !important;
  }
}

@each $color,
$value in $theme-light-colors {
  .border-card-unit-light-#{$color} {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
    border-radius: 8px;
    border-color: $value !important;
  }
}

.text-show {
  font-size: 14px;
}

.bg-card-report {
  background-color: $primary
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: textfield;
}

.input-group-text-sm {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  text-align: center;
  white-space: nowrap;
  background-color: #f3f6f9;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}

.input-group {
  .form-control.form-control-hover {
    border: 0px solid #e4e6ef;
  }

  .input-group-text.input-group-text-hover {
    background-color: white;
    border: 0px solid #ced4da;
  }

  .input-group-text-hover i {
    color: white;
  }

  &:hover {
    .form-control.form-control-hover {
      border: 1px solid #e4e6ef;
    }

    .input-group-text.input-group-text-hover {
      background-color: #e9ecef;
      border: 1px solid #ced4da;
    }

    .input-group-text-hover i {
      color: #b5b5c3;
    }
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;

}

.text-input-hover {
  color: #7a869a
}

// css calculator
.calculator {
  width: 100%;
  max-width: 400px;
  background-color: #FFF;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: "0px 2px 64px rgba(0, e, e, 0.2)";
}

.display {
  padding: 16px;
  text-align: right;
  background-color: var(--dark);
  color: var(--light);
  font-size: 24px;
  font-weight: 300;
}

.display span {
  font-size: 14px;
  color: #888;
}

button {
  appearance: none;
  border: none;
  outline: none;
  color: #FFF;
  font-size: 20px;
  padding: 16px;
  cursor: pointer;
  transition: 0.4s;
}

button:hover {
  opacity: 0.9;
}

.operators {
  display: flex;
}

.operators button {
  flex: 1 1 0%;
  background-color: var(--primary);
  font-weight: 700;
}

.digits {
  display: flex;
  flex-wrap: wrap;
}

.digits button {
  flex: 1 1 33.333%;
  max-width: 33.333%;
  background-color: var(--dark);
}

.font-size-icon-md {
  font-size: 0.95rem !important;
}

.bg-whitesmoke {
  background-color: whitesmoke;
}

.symbol-label-rectangle {
  width: 178px !important;
  height: 100px !important;
}

.card-shadow:hover {
  box-shadow: 0 0.1rem 1rem 0.25rem rgba($black, 0.05);
}

.input-border-bottom {
  border: 0;
  width: 90%;
  text-align: center;
  background: transparent;

}

.input-border-bottom:focus {

  outline: 0;
  border-bottom: 1px solid #e4e6ef;
}

.width-max-auto {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}

i {
  content: "\2713";
}

@media print {
  body * {
    visibility: hidden;
  }

  .printclass * {
    visibility: visible;
  }
}

.text-nowrap {
  white-space: nowrap
}

.card-scroll-filter {
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.card-scroll-filter::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

// gantt
.gantt_task_line {
  border-color: rgba(0, 0, 0, 0.25);
}

.gantt_task_line .gantt_task_progress {
  background-color: #00AD7C !important;
  opacity: 1;
}

.gantt_task_line .gantt_task_content {
  color: #fff;
  text-align: start;
}

.gantt_task_line.rented {
  background-color: #52D681 !important;
}

.gantt_task_line.empty {
  background-color: #FC345C !important;
}

.gantt_task_line.empty .gantt_task_progress {
  background-color: #FC345C !important;
  opacity: 1;
}

.gantt_task_line.expiration_soon {
  background-color: #FFA45B !important;
}

.gantt_task_line.expiration {
  background-color: #FF4646 !important;
}

.gantt_marker {
  height: 100%;
  width: 2px;
  top: 0;
  position: absolute;
  text-align: center;
  background-color: rgba(255, 0, 0, .4);
  box-sizing: border-box;
}

.hideTask {
  visibility: hidden;
}

.this_day {
  background: #f4f7f4 !important;
}

.progress_value {
  position: absolute;
  background: red;
  opacity: 0.7;
  z-index: 1;
  pointer-events: none;
}

.horizontal-scrolling ::-webkit-scrollbar {
  display: none;
}

.card-summary {
  border-radius: 12px;
  // box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.3);
  transition: 0.15s all;
}

.card-summary:hover {
  box-shadow: 0px 20px 40px -15px rgba(0, 0, 0, 0.5);
  // transform: translateY(-1px);
}

.rounded-left {
  border-top-left-radius: 0.42rem !important;
  border-bottom-left-radius: 0.42rem !important;
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}